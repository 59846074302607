import ContactSupport from '../../components/ContactSupport';
import { useSettings } from '../../contexts/SettingsContext';
import { useMemo } from 'react';
import { Issue, IssueRow, StatusTab } from '../types';
import { Link } from 'react-router-dom';
import { inventoryItemPath, issuePath, orderPath, partPath, runViewPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeDisplay from '../../components/DateTimeDisplay';
import Label from '../../components/Label';
import { StatusType } from 'shared/lib/types/postgres/issues';
import { ITEM_REFERENCE_TYPE, ORDER_REFERENCE_TYPE, PART_REFERENCE_TYPE, UNLINKED_REFERENCE_TYPE } from '../constants';
import Grid, { GridColumn } from '../../elements/Grid';
import { SortColumn } from 'react-data-grid';
import useParts from '../../manufacturing/hooks/useParts';
import { getScrollToUrlParams } from '../../lib/scrollToUtil';
import { isClosedIssue, isOpenIssue, sortStatusColumn } from '../lib/issues';
import projectUtil from '../../lib/projectUtil';
import TitleCellRenderer from '../../elements/renderers/TitleCell';

interface IssuesGridProps {
  issues: Array<Issue>;
  selectedStatus: StatusTab;
  verticalPadding?: number;
  setSearchTerm: (searchTerm: string) => void;
}

const IssuesGrid = ({ issues, selectedStatus, verticalPadding = 0, setSearchTerm }: IssuesGridProps) => {
  const { currentTeamId } = useDatabaseServices();
  const { isIssuesEnabled, projects } = useSettings();
  const { getPartByRevisionId } = useParts();

  const revisionIdToPartId = useMemo(() => {
    if (!issues) {
      return {};
    }
    const partIssues = issues.filter((issue) => issue.referenceType === PART_REFERENCE_TYPE);
    const revisionIds = partIssues.map((issue) => issue.referenceId);
    const revisionIdsUnique: string[] = Array.from(new Set(revisionIds));

    const revIdToPartId = {};
    for (const revisionId of revisionIdsUnique) {
      const part = getPartByRevisionId(revisionId);
      if (part) {
        revIdToPartId[revisionId] = part.id;
      }
    }
    return revIdToPartId;
  }, [issues, getPartByRevisionId]);

  const defaultSort = [
    {
      columnKey: 'createdAt',
      direction: 'DESC',
    },
  ] as Array<SortColumn>;

  const columns = useMemo(() => {
    const columns: Array<GridColumn<IssueRow>> = [
      {
        key: 'id',
        name: 'Issue',
        sortable: true,
        width: '20%',
        renderCell({ row }: { row: IssueRow }) {
          const link = issuePath(currentTeamId, `${row.id}`);
          const from = selectedStatus === StatusTab.Closed ? 'closed' : 'open';
          return <TitleCellRenderer id={row.id} title={row.title} link={link} from={from} />;
        },
        comparator: (a: IssueRow, b: IssueRow) => +a.id - +b.id,
      },
      {
        key: 'project',
        name: 'Project',
        width: '12%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          if (!row.projectId) {
            return <></>;
          }
          const projectName = projectUtil.getProjectName(projects, row.projectId) || '';
          return (
            <Label text={projectName} color="bg-gray-200" clickable={true} onClick={() => setSearchTerm(projectName)} />
          );
        },
        comparator: (a: IssueRow, b: IssueRow) => {
          return (projectUtil.getProjectName(projects, a.projectId) ?? '').localeCompare(
            projectUtil.getProjectName(projects, b.projectId) ?? ''
          );
        },
      },
      {
        key: 'sub_status',
        name: 'Status',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          if (!row.status) {
            return null;
          }
          if (row.status.status === StatusType.Closed && row.subStatus) {
            return <Label text={row.subStatus.label} color={row.subStatus.color} size="xs" />;
          }
          return <Label text={row.status.name} color={row.status.color} size="xs" />;
        },
        comparator: (a: IssueRow, b: IssueRow) => sortStatusColumn(a, b),
      },
      {
        key: 'severity',
        name: 'Severity',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          return row.severity && <Label text={row.severity.name} color={row.severity.color} size="xs" />;
        },
        comparator: (a: IssueRow, b: IssueRow) =>
          (a.severity?.id || Number.MAX_SAFE_INTEGER) - (b.severity?.id || Number.MAX_SAFE_INTEGER),
      },
      {
        key: 'assignee',
        name: 'Assignee',
        width: '15%',
        sortable: true,
      },
      {
        key: 'referenceType',
        name: 'Type',
        width: '10%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          if (row.referenceType === UNLINKED_REFERENCE_TYPE) {
            return <></>;
          }
          return row.referenceType && <div className="capitalize">{row.referenceType}</div>;
        },
      },
      {
        key: 'createdAt',
        name: 'Created At',
        width: '15%',
        sortable: true,
        renderCell({ row }: { row: IssueRow }) {
          return (
            <span className="text-sm text-gray-600">
              <DateTimeDisplay timestamp={row.createdAt} wrap={false} hasTooltip={true} />
            </span>
          );
        },
      },
      {
        key: 'reference',
        name: 'Reference',
        width: '8%',
        sortable: false,
        headerCellClass: 'flex items-center justify-start',
        cellClass: 'flex items-center justify-center',
        renderCell({ row }: { row: IssueRow }) {
          if (row.referenceType === UNLINKED_REFERENCE_TYPE) {
            return <></>;
          }
          let link = '';
          if (row.runId) {
            link = `${runViewPath(currentTeamId, row.runId)}${
              row.referenceId ? getScrollToUrlParams({ id: row.referenceId, type: 'step' }) : ''
            }`;
          } else if (row.referenceId && row.referenceType === ITEM_REFERENCE_TYPE) {
            link = inventoryItemPath(currentTeamId, row.referenceId);
          } else if (row.referenceId && revisionIdToPartId && row.referenceType === PART_REFERENCE_TYPE) {
            link = partPath(currentTeamId, revisionIdToPartId[row.referenceId], row.referenceId);
          } else if (row.referenceId && row.referenceType === ORDER_REFERENCE_TYPE) {
            link = orderPath(currentTeamId, row.referenceId);
          }
          return (
            <Link to={link} className="text-blue-600 hover:underline">
              <FontAwesomeIcon className="" icon="link" />
            </Link>
          );
        },
      },
    ];
    return columns;
  }, [currentTeamId, projects, revisionIdToPartId, selectedStatus, setSearchTerm]);

  const rows = useMemo(() => {
    const issueList: IssueRow[] =
      issues
        ?.filter((issue) => {
          if (selectedStatus === StatusTab.Open) {
            return isOpenIssue(issue);
          }
          return isClosedIssue(issue);
        })
        .map((issue) => {
          return {
            id: issue.id,
            title: issue.title,
            severity: issue.severity,
            createdAt: issue.createdAt,
            assignee: issue.assignee,
            runId: issue.runId,
            referenceId: issue.referenceId,
            referenceType: issue.referenceType,
            status: issue.status,
            subStatus: issue.subStatus,
            projectId: issue.projectId,
          };
        }) ?? [];
    return issueList;
  }, [issues, selectedStatus]);

  if (!(isIssuesEnabled && isIssuesEnabled())) {
    return <ContactSupport />;
  }

  return (
    <Grid
      gridKey={selectedStatus}
      columns={columns}
      rows={rows}
      usedVerticalSpace={verticalPadding}
      emptyRowMessage="No Issues Found"
      defaultSort={defaultSort}
    />
  );
};

export default IssuesGrid;
