import { useCallback, useMemo, useState } from 'react';
import { SelectColumn } from 'react-data-grid';
import { Maybe } from 'yup/lib/types';
import Button from '../../components/Button';
import { RowWithProjectName } from '../../components/Home/GridExpandCollapseButton';
import renderProject from '../../components/Home/Renderers/Project';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';
import { useSettings } from '../../contexts/SettingsContext';
import Grid, { GridColumn, Grouping, TextAlign } from '../../elements/Grid';
import GridComparators from '../../elements/lib/gridComparators';
import projectGroupCell from '../../elements/renderers/ProjectGroupCell';
import { ColumnOverrides, applyOverrides } from '../../lib/gridUtils';
import projectUtil, { DEFAULT_PROJECT_NAME } from '../../lib/projectUtil';
import useTestingAuth from '../hooks/useTestingAuth';
import { calculateAssessmentLevel } from '../libs/hazards';
import { TestCase } from '../types';
import AssessmentIndicator from './AssessmentIndicator';

const MAIN_VERTICAL_PADDING = 165;
const BASE_ROW_HEIGHT = 35;
const ADDITIONAL_ROW_HEIGHT = 24;
const GROUP_ROW_HEIGHT_PX = 30;
const ROW_HEIGHT = (row, allExpanded = false) => {
  return (
    BASE_ROW_HEIGHT +
    (allExpanded
      ? ADDITIONAL_ROW_HEIGHT * Math.max(1, row.test_case_conditions.length - 1, (row.hazards?.length ?? 1) - 1)
      : ADDITIONAL_ROW_HEIGHT)
  );
};

type TestCaseRow = TestCase & RowWithProjectName;

interface TestCasesTableProps {
  testCases: Array<TestCase>;
  selectedRows: ReadonlySet<string>;
  usedVerticalSpace?: number;
  columnOverrides?: ColumnOverrides;
  rowGrouping?: Maybe<Grouping<TestCase>>;
  onClickedRow: (id: string) => void;
  onSelectedRowsChange: (selectedRows: Set<string>) => void;
}

const TestCasesTable = ({
  testCases,
  selectedRows,
  usedVerticalSpace = MAIN_VERTICAL_PADDING,
  columnOverrides,
  rowGrouping,
  onClickedRow,
  onSelectedRowsChange,
}: TestCasesTableProps) => {
  const [allExpanded, setAllExpanded] = useState<boolean>(true);
  const { hasEditPermission } = useTestingAuth();
  const { projects } = useSettings();

  const testCaseRows = useMemo(
    () =>
      testCases.map((testcase) => ({
        ...testcase,
        projectName: projectUtil.getProjectName(projects, testcase.project_id) || DEFAULT_PROJECT_NAME,
      })) as Array<TestCaseRow>,
    [projects, testCases]
  );

  const rowHeightGetter = useMemo(() => {
    if (rowGrouping) {
      return ({ type, row }) => {
        if (type === 'GROUP') {
          return GROUP_ROW_HEIGHT_PX;
        }
        return ROW_HEIGHT(row, allExpanded);
      };
    }
    return ROW_HEIGHT;
  }, [allExpanded, rowGrouping]);

  const rowKeyGetter = (row: TestCase) => {
    return row.id;
  };

  const onClickRow = useCallback(
    (id) => {
      onClickedRow(id);
    },
    [onClickedRow]
  );

  const testCasesColumns = useMemo<Array<GridColumn<TestCaseRow>>>(() => {
    const baseColumns = [
      SelectColumn,
      {
        key: 'name',
        name: 'Test Point',
        sortable: true,
        width: '40%',
        renderCell({ row }: { row: TestCaseRow }) {
          return <div className=" text-gray-900">{row.name}</div>;
        },
      },
      {
        key: 'projectName',
        name: 'Project',
        sortable: true,
        renderCell: ({ row }) => renderProject(row.projectName === DEFAULT_PROJECT_NAME ? '' : row.projectName),
        renderGroupCell: projectGroupCell,
        comparator: GridComparators.project,
      },
      {
        key: 'conditions',
        name: 'Conditions',
        sortable: false,
        width: '17%',
        renderCell({ row }: { row: TestCaseRow }) {
          return (
            <div className="flex-auto flex flex-col gap-y-1 leading-4">
              {!allExpanded && row.test_case_conditions.length > 1 && (
                <>
                  <div className=" text-gray-900 flex flex-row gap-x-[2px]">
                    {`${row.test_case_conditions[0].name}: ${row.test_case_conditions[0].value}`}
                    <UnitDisplay unit={row.test_case_conditions[0].units} />
                  </div>
                  <div className=" text-gray-900">...</div>
                </>
              )}
              {(allExpanded || row.test_case_conditions.length <= 1) &&
                row.test_case_conditions.map((condition) => (
                  <div key={condition.condition_id} className=" text-gray-900 flex flex-row gap-x-[2px]">
                    {`${condition.name}: ${condition.value}`}
                    <UnitDisplay unit={condition.units} />
                  </div>
                ))}
            </div>
          );
        },
      },
      {
        key: 'hazard',
        name: 'Hazard',
        sortable: true,
        width: '15.9%',
        renderCell({ row }: { row: TestCaseRow }) {
          return (
            <div className="flex-auto flex flex-col gap-y-1 leading-4">
              {!allExpanded && row.hazards && row.hazards.length > 1 && (
                <>
                  <div className="flex flex-row gap-x-2 items-center">
                    <AssessmentIndicator analysis={row.hazards[0]} />
                    <div key={row.hazards[0].name} className=" text-gray-900">
                      {row.hazards[0].name}
                    </div>
                  </div>
                  <div className=" text-gray-900">...</div>
                </>
              )}
              {row.hazards &&
                (allExpanded || row.hazards.length <= 1) &&
                row.hazards.map((hazard) => (
                  <div key={hazard.name} className="flex flex-row gap-x-2 items-center">
                    <AssessmentIndicator analysis={hazard} />
                    <div className=" text-gray-900">{hazard.name}</div>
                  </div>
                ))}
            </div>
          );
        },
        comparator: (a: TestCaseRow, b: TestCaseRow) => {
          const hazardLevelA = a.hazards && a.hazards.length > 0 ? calculateAssessmentLevel(a.hazards[0]) : 0;
          const hazardLevelB = b.hazards && b.hazards.length > 0 ? calculateAssessmentLevel(b.hazards[0]) : 0;
          return hazardLevelA - hazardLevelB;
        },
      },
      {
        key: 'expand_collapse',
        name: (
          <Button
            type="tertiary"
            leadingIcon={allExpanded ? 'down-left-and-up-right-to-center' : 'expand-alt'}
            onClick={() => setAllExpanded(!allExpanded)}
          />
        ),
        align: TextAlign.Right,
        sortable: false,
        width: 100,
        renderCell({ row }: { row: TestCaseRow }) {
          return !hasEditPermission(row.project_id) ? (
            <></>
          ) : (
            <div>
              <Button
                isDisabled={selectedRows.size > 0}
                type="tertiary"
                leadingIcon="pencil-alt"
                onClick={() => onClickRow(row.id)}
              >
                Edit
              </Button>
            </div>
          );
        },
      },
    ];
    if (columnOverrides) {
      return applyOverrides(baseColumns, columnOverrides);
    }
    return baseColumns;
  }, [allExpanded, hasEditPermission, columnOverrides, onClickRow, selectedRows.size]);

  return (
    <div className="mt-2">
      <Grid
        columns={testCasesColumns}
        rows={testCaseRows}
        rowHeight={rowHeightGetter}
        rowGrouping={rowGrouping}
        emptyRowMessage="No matching test points found"
        usedVerticalSpace={usedVerticalSpace}
        selectedRows={selectedRows}
        onSelectedRowsChange={onSelectedRowsChange}
        rowKeyGetter={rowKeyGetter}
      />
    </div>
  );
};

export default TestCasesTable;
